import React from 'react';
export const FurnitureIcon = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18.1'
      height='24.1'
      viewBox='0 0 18.1 24.1'
      {...props}
    >
      <g id='Furniture' transform='translate(-47.95 -583.95)'>
        <path
          id='Path_17415'
          data-name='Path 17415'
          d='M66.684,585.288A1.5,1.5,0,0,0,65.2,584H58.8a1.5,1.5,0,0,0-1.485,1.288L56,596H68Zm-8.625.1a.753.753,0,0,1,.741-.638h6.4a.753.753,0,0,1,.741.638l1.212,9.862h-10.3Z'
          transform='translate(-5)'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='0.1'
        />
        <path
          id='Path_17416'
          data-name='Path 17416'
          d='M65.25,608H64.5a.75.75,0,0,0-.75.75v.75a.75.75,0,0,0,.75.75v3h-.75a1.5,1.5,0,0,0-1.5-1.5H51.75a1.5,1.5,0,0,0-1.5,1.5H49.5v-3a.75.75,0,0,0,.75-.75v-.75a.75.75,0,0,0-.75-.75h-.75a.75.75,0,0,0-.75.75v.75a.75.75,0,0,0,.75.75v3a.75.75,0,0,0,.75.75h.952a1.5,1.5,0,0,0,1.3.75H55.5v1.5a.75.75,0,0,0,.75.75h.375v.75h-2.25a1.877,1.877,0,0,0-1.875,1.875v1.19a1.125,1.125,0,1,0,.75,0v-1.19a1.126,1.126,0,0,1,1.125-1.125h2.25v2.315a1.125,1.125,0,1,0,.75,0V618.5h2.25a1.126,1.126,0,0,1,1.125,1.125v1.19a1.125,1.125,0,1,0,.75,0v-1.19a1.877,1.877,0,0,0-1.875-1.875h-2.25V617h.375a.75.75,0,0,0,.75-.75v-1.5h3.75a1.5,1.5,0,0,0,1.3-.75H64.5a.75.75,0,0,0,.75-.75v-3a.75.75,0,0,0,.75-.75v-.75A.75.75,0,0,0,65.25,608Zm-16.5,1.5v-.75h.75v.75Zm4.125,12.75a.375.375,0,1,1,.375-.375A.376.376,0,0,1,52.875,622.25Zm8.25-.75a.375.375,0,1,1-.375.375A.376.376,0,0,1,61.125,621.5ZM57,622.25a.375.375,0,1,1,.375-.375A.376.376,0,0,1,57,622.25Zm.75-6h-1.5v-1.5h1.5Zm4.5-2.25H51.75a.75.75,0,0,1,0-1.5h10.5a.75.75,0,0,1,0,1.5Zm3-4.5H64.5v-.75h.75Z'
          transform='translate(0 -15)'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='0.1'
        />
      </g>
    </svg>
  );
};
