import React from 'react';
export const Handbag = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23.878'
      height='24.3'
      viewBox='0 0 23.878 24.3'
      {...props}
    >
      <path
        id='Bags'
        d='M334.087,36.795h6.808a2.594,2.594,0,0,1,1.833.763h0a2.592,2.592,0,0,1,.763,1.833v2.718h3.328a2.384,2.384,0,0,1,1.686.7h0a2.383,2.383,0,0,1,.7,1.686v3.227a5.569,5.569,0,0,1-.606,2.532v6.066a4.48,4.48,0,0,1-4.469,4.469H330.795a4.567,4.567,0,0,1-4.556-4.556V50.26a5.569,5.569,0,0,1-.606-2.532V44.5a2.384,2.384,0,0,1,.7-1.687h0a2.382,2.382,0,0,1,1.688-.7h3.328v-2.58a2.742,2.742,0,0,1,2.736-2.736Zm13.29,15.077a5.6,5.6,0,0,1-3.443,1.462l-.048,0h-.009l-.061,0h-.01l-.066,0h-3.826v.718a1.838,1.838,0,0,1-.541,1.3v0a1.841,1.841,0,0,1-1.3.541h-1.245a1.891,1.891,0,0,1-1.339-.556v0a1.887,1.887,0,0,1-.556-1.337v-.668H331.1l-.066,0h-.01l-.06,0h-.009l-.048,0a5.6,5.6,0,0,1-3.443-1.462v4.367a3.339,3.339,0,0,0,3.328,3.328h13.341a3.252,3.252,0,0,0,3.241-3.241V51.872Zm-3.883-8.533H328.022a1.159,1.159,0,0,0-.82.342h0a1.158,1.158,0,0,0-.339.82v3.227a4.405,4.405,0,0,0,4.161,4.383h0l.11,0h12.574l.11,0h0a4.4,4.4,0,0,0,4.161-4.383V44.5a1.157,1.157,0,0,0-.339-.82l0,0a1.157,1.157,0,0,0-.82-.339Zm-4.807,10.006h-2.53v.668a.669.669,0,0,0,.2.472h0a.667.667,0,0,0,.471.2h1.245a.615.615,0,0,0,.436-.182h0a.62.62,0,0,0,.18-.437v-.718Zm2.208-15.322h-6.808a1.515,1.515,0,0,0-1.509,1.509v2.58h9.688V39.394a1.365,1.365,0,0,0-.4-.968l0,0A1.365,1.365,0,0,0,340.895,38.023Z'
        transform='translate(-325.483 -36.645)'
        fill='currentColor'
        stroke='#fff'
        strokeWidth='0.3'
        fillRule='evenodd'
      />
    </svg>
  );
};
